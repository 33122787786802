import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class Terms extends Component {


    componentDidMount() {
        // Track pageview
        this.props.pageview();
    }

    
    render() {
        return (
            <div className="content-container">
                <div className="content-wrapper">
                    <div className="section">
                        <div className="textbox text-title text-bold">
                            Terms of service
                        </div>
                        <div className="textbox">
                            Last updated June 15, 2022
                        </div>

                        <div className="textbox text-title text-bold">
                            Agreement to terms
                        </div>
                        <div className="textbox">
                            <p className="textbox__paragraph">
                                These Terms of Service constitute a legally binding agreement made between you, whether personally or on behalf of an entity ("you") and SkinScan ("SkinScan", "SkinScan.AI", "we", "us", or "ous"), concerning your access to and use of the skinscan.ai website as well as any other media form, media channel, mobile website or mobile application related, linked, or otherwise connected thereto (collectively, the "Site"). You agree that by accessing the Site, you have read, understood, and agreed to be bound by all of these Terms of Service. If you do not agree with all of these terms of service, then you are expressly prohibited from using the site and you must discontinue use immediately.
                            </p>
                            <p className="textbox__paragraph">
                                Supplemental terms and conditions or documents that may be posted on the Site from time to time are hereby expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make changes or modifications to these Terms of Use at any time and for any reason. We will alert you about any changes by updating the “Last updated” date of these Terms of Use, and you waive any right to receive specific notice of each such change. It is your responsibility to periodically review these Terms of Use to stay informed of updates. You will be subject to, and will be deemed to have been made aware of and to have accepted, the changes in any revised Terms of Use by your continued use of the Site after the date such revised Terms of Use are posted.
                            </p>
                            <p className="textbox__paragraph">
                                From time to time SkinScan may offer additional features or services through the site. You may be required to enter into additional agreements or to provide authorizations before you can access such features or services.
                            </p>
                            <p className="textbox__paragraph">
                                The information provided on the Site is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject us to any registration requirement within such jurisdiction or country. Accordingly, those persons who choose to access the Site from other locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the extent local laws are applicable.
                            </p>
                            <p className="textbox__paragraph">
                                Please review all of the <Link className="text-link-inline" to="/terms">Terms</Link> and the <Link className="text-link-inline" to="/instructions">Instructions for use</Link> carefully before using the Service. In addition to these Terms, the Online Services are also governed by our <Link className="text-link-inline" to="/privacy">Privacy Policy</Link> and <Link className="text-link-inline" to="/cookies">Cookie Policy</Link>.
                            </p>
                            <p className="textbox__paragraph">
                                The Site is intended for users who are at least 18 years old. Persons under the age of 18 are not permitted to use or register for the Site.
                            </p>
                        </div>

                        <div className="textbox text-title text-bold">
                            Services
                        </div>
                        <div className="textbox">
                            <p className="textbox__paragraph">
                                In order to use the SkinCheck services, you need to register an account and login through the site by providing your email address as a username and a password. You need to confirm your email address in order to receive a SkinScan account. You can personalize your account by providing additional information regarding your risk profile and skin type.
                            </p>
                            <p className="textbox__paragraph">
                                The intention of the SkinScan services is to help you monitor your skin, provide you with the opportunity to assess the risk profile of your skin, and inform you of potential health risks.
                            </p>
                            <p className="textbox__paragraph">
                                SkinScan services can provide an immediate risk indication for the most common types of skin cancer, based on a picture of a specific spot on the skin. Based on the risk assessment, the skinScan service may provide a recommendation to visit a healthcare professional for further review. The service creates awareness for the skin health and provides guidance in discussions with healthcare professionals.
                            </p>
                            <p className="textbox__paragraph">
                                SkinScan services are not intended to replace traditional methods to evaluate skin cancer risk levels, and they are not a substitute for visits to a healthcare professional. SkinScan services do not provide a diagnosis and any assessments made are intended to create skin health awareness.
                            </p>
                        </div>

                        <div className="textbox text-title text-bold">
                            Personal services
                        </div>
                        <div className="textbox">
                            <p className="textbox__paragraph">
                                SkinScan services are exclusively provided to the user and individual account holder (Personal Services). A user must not use their personal account to perform SkinScan services for other individuals or other individuals' skin spots.
                            </p>
                            <p className="textbox__paragraph">
                                SkinScan is not responsible for personal health information or privacy-sensitive information of users outside the user and individual account holder.
                            </p>
                        </div>

                        <div className="textbox text-title text-bold">
                            Medical treatment and recommendations
                        </div>
                        <div className="textbox">
                            <p className="textbox__paragraph">
                                SkinScan is designed to support the health decisions and choices that you make for yourself. In the context of health and wellness, your decisions should always be made in conjunction with your attending physician or health care provider.
                            </p>
                        </div>

                        <div className="textbox text-title text-bold">
                            Data collection and data privacy
                        </div>
                        <div className="textbox">
                            <p className="textbox__paragraph">
                                SkinScan services use certain data. We collect these data to provide our services, including the risk assessment, feedback, marketing, customer care, non-commercial communications, research activities, to manage and improve our services, and for legal obligations. For further information on how we collect and store your data, please review our <Link className="text-link-inline" to="/privacy">Privacy Policy</Link>.
                            </p>
                        </div>

                        <div className="textbox text-title text-bold">
                            Research follow-up policy
                        </div>
                        <div className="textbox">
                            <p className="textbox__paragraph">
                                At SkinScan we are constantly improving our services by performing research activities. As part of these research activities we may perform checks on the pictures to verify the accuracy of our services. In case we are of the opinion that it will benefit your health if we share the outcome of those checks, this review will feed into the algorithm, which will determine if we should contact you outside of our regular automated communication. In case of a serious finding, we may get in touch with you via your email address or try to get in touch with you via the phone number you provided to us.
                            </p>
                        </div>

                        <div className="textbox text-title text-bold">
                            Instructions for use
                        </div>
                        <div className="textbox">
                            <p className="textbox__paragraph">
                                SkinScan recommends you to carefully read the <Link className="text-link-inline" to="/terms">Terms</Link> before using our services. It is your obligation to follow these instructions and to make sure that any pictures submitted comply with the requirements listed below.
                            </p>
                            <p className="textbox__paragraph">
                                Pictures of the skin that are submitted for skin health assessments, must:
                                <ul>
                                    <li>
                                        Be photographed directly, that is without the use of a dermascope, lens, mirror, or other appliances.
                                    </li>
                                    <li>
                                        Be in high quality, that is a resolution of at least 2000 by 2000 pixels.
                                    </li>
                                    <li>
                                        Be in .png, .jpg, or .jpeg file format.
                                    </li>
                                    <li>
                                        Show the skin spot or mole in the center of the picture.
                                    </li>
                                    <li>
                                        Be taken from a feasible distance of about 15cm (6in) from the skin.
                                    </li>
                                    <li>
                                        Contain a skin spot or mole that is sufficiently visible and different in color from the surrounding skin. For example, no skin spots on dark skin (type V and VI per the Fitzpatrick scale) or on sunburnt skin.
                                    </li>
                                    <li>
                                        Be taken with good light conditions, that is no lack of light and no direct light reflections.
                                    </li>
                                    <li>
                                        Use a plain background, ideally white or black.
                                    </li>
                                    <li>
                                        Not contain any previously damaged skin spots or non-intact skin, like open sores, ulcers, bleeding, and scabs.
                                    </li>
                                    <li>
                                        Not contain nails.
                                    </li>
                                    <li>
                                        Not contain any visible scars.
                                    </li>
                                    <li>
                                        Not contain foreign matter, like marker, tattoos, sunscreen, skin cream, powder, etc.
                                    </li>
                                    <li>
                                        Not contain skin spots or moles that are covered by a significant amount of hair.
                                    </li>
                                    <li>
                                        Not contain skin spots or moles on mucosal surfaces, like lips and genitals.
                                    </li>
                                    <li>
                                        Contain human skin.
                                    </li>
                                </ul>
                            </p>
                            <p className="textbox__paragraph">
                                When a picture is submitted that does not comply with all of the aforementioned requirements, the risk assessment may be influenced in an unpredictable way. In case you have doubts about a skin spot or mole or when it changes, is irritated, or bleeds, always consult with your medical professional.
                            </p>
                        </div>

                        <div className="textbox text-title text-bold">
                            No warranties
                        </div>
                        <div className="textbox">
                            <p className="textbox__paragraph">
                                You understand and agree that SkinScan, the site and any related services, content or information contained on or provided by SkinScan is provided on an "as is" basis. SkinScan does not make any express or implied warranties, representations or endorsements whatsoever (including without limitation warranties of title or noninfringement, or the implied warranties of merchantability or fitness for a particular purpose) with regard to the service or any merchandise, information or service provided through the site and service.
                            </p>
                            <p className="textbox__paragraph">
                                In addition, SkinScan does not guarantee that use of the site and any services will be free from technological difficulties including, but not limited to, unavailability of information, downtime, service disruptions, viruses or worms. You understand that you are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for accuracy of data input and output.
                            </p>
                        </div>

                        <div className="textbox text-title text-bold">
                            Fees for services
                        </div>
                        <div className="textbox">
                            <p className="textbox__paragraph">
                                In order to use the site and services, you may be required to submit your billing and payment information. Before you are using the services within the site, you will be required to pay the amounts due as listed on the site. You expressly authorize us to charge you for any amounts due using your payment information we file. All payment information may be processed, stored, and secured by third party payment providers.
                            </p>
                            <p className="textbox__paragraph">
                                Your plan will be automatically renewed unless you explicitely state that you do not want your plan to be automatically renewed.
                            </p>
                            <p className="textbox__paragraph">
                                SkinScan collaborates with certain health insurers and corporates. When you are admitted to the use of our services or the plan via one of our partners, fees for service and duration of use may differ and are dependent on the contract SkinScan has with the partner.
                            </p>
                        </div>

                        {/* <div className="textbox text-title text-bold">
                            Refund
                        </div>
                        <div className="textbox">
                            <p className="textbox__paragraph">
                                SkinScan has a money back guarantee in place for dissatisfied users who express it as such within 30 days of purchase of either a one (1) year or one (1) month SkinScan premium membership plan. This may be invoked one time per user. This is a show of goodwill on the part of SkinScan, done for customer satisfaction purpose only, and in no way is correlated to any actual or potential malfunction of the device. Please contact support@skinscan.ai for claims.
                            </p>
                            <p className="textbox__paragraph">
                                Except as aforementioned money back guarantee, other paid services are non-refundable. If you believe that there has been an error in billing or you are entitled to a refund, please contact us at support@skinscan.ai.
                            </p>
                            <p className="textbox__paragraph">
                                Credits and giftcards that you have purchased or received in your wallet are not exchangeable for money. 
                            </p>
                        </div> */}

                        <div className="textbox text-title text-bold">
                            Discontinuance of services
                        </div>
                        <div className="textbox">
                            <p className="textbox__paragraph">
                                SkinScan reserves the right to modify, discontinue, temporarily or permanently, the site and services, or any part thereof, at any time, with or without notice. When appropriate, SkinScan will give you a proportional refund. In addition, your access to the Services may be discontinued at any time if you violate the terms of this agreement or if we determine, if you purposely misuse the service or act in an unethical way, or for any other reason, that it is no longer appropriate for you to have access to the Services.
                            </p>
                        </div>

                        <div className="textbox text-title text-bold">
                            Disclaimer, limitations and exclusions of liability
                        </div>
                        <div className="textbox">
                            <p className="textbox__paragraph">
                                The SkinScan site and services are being provided to monitor, track and understand your skin health. The site and services are intended to help understand and support self assessments, but are not to be used or relied on solely for any diagnostic or treatment purposes and they do not replace a visit to the doctor. Any reliance by you is at your own discretion and risk. The assessment is provided to estimate the risk of an individual to develop the most common forms of skin cancer (i.e. melanoma, basal cell carcinoma, squamous cell carcinoma) over time. The risk assessment is based on a smartphone generated picture which may not be an actual representation of any discomfort you experience (itching, irritation, bleeding), therefore, it is highly recommended that you discuss your personal risk factors and results of your assessment with your doctor. SkinScan shall not be liable for any decision you take not to discuss your skin health, personal risk factors and/or results of your assessment with your doctor.
                            </p>
                            <p className="textbox__paragraph">
                                SkinScan makes no representation or warranties relating to the information contained in the online and in-app library. We do not warrant that the medical information on the site will be available, complete, completely accurate, or up-to-date.
                            </p>
                            <p className="textbox__paragraph">
                                Notwithstanding the foregoing, SkinScan reserves the right, but is not obligated, to refuse to post any content and/or remove your access to the site and services.
                            </p>
                            <p className="textbox__paragraph">
                                SkinScan has no responsibility or liability for any kind of action that you do or do not take because of or despite of the information on the site and services. If you are experiencing a medical emergency or you have any concern about your medical condition in any way you should seek immediate medical attention. You should not delay seeking medical advice, stop treatment, or disregard medical advice because of the information contained in the site and services.
                            </p>
                            <p className="textbox__paragraph">
                                SkinScan does not guarantee correct functioning of the site and services if you do not comply with all requirements for use or while you are subject to a contra-indication for the use.
                            </p>
                            <p className="textbox__paragraph">
                                Our liability in connection with the site and services shall be limited to (1.) direct damages or (in case of insurance coverage) (2.) the amount paid under our insurance policy, unless it concerns liability:
                                <ul>
                                    <li>
                                        for death or personal injury resulting from gross negligence;
                                    </li>
                                    <li>
                                        for damage resulting from fraud or fraudulent misrepresentation;
                                    </li>
                                    <li>
                                        which on the basis of the applicable law cannot be limited.
                                    </li>
                                </ul>
                            </p>
                            <p className="textbox__paragraph">
                                To the extent that our site and the information and services therein are provided free of charge, we will not be liable for any loss or damage of any nature.
                            </p>
                            <p className="textbox__paragraph">
                                We will not be liable to you in respect of any losses arising out of any event or events beyond our reasonable control.
                            </p>
                            <p className="textbox__paragraph">
                                The Services are for domestic personal use only. Therefore we will not be liable to you in respect of any business losses, including (without limitation) loss of or damage to profits, income, revenue, use, production, anticipated savings, business contracts, commercial opportunities or goodwill.
                            </p>
                            <p className="textbox__paragraph">
                                You agree that you will not bring any claim personally against our officers or employees in respect of any losses you suffer in connection with the site and services or these terms.
                            </p>
                            <p className="textbox__paragraph">
                                We shall not be liable for any content you publish on social media by using a social media plugin on our site.
                            </p>
                            <p className="textbox__paragraph">
                                Where the site and services are made available to you via an Insurance Company or Local Health System, this party and its affiliates including its officers, agents and employees shall not be liable to you in respect of any losses liabilities claims and expenses howsoever arising, from the use of the site and services.
                            </p>
                        </div>

                        <div className="textbox text-title text-bold">
                            Copyright and trademark information
                        </div>
                        <div className="textbox">
                            <p className="textbox__paragraph">
                                The marks "SkinScan" and "SkinScan.AI" and the SkinScan logo are trademarks and/or service marks of SkinScan. The content and design of the SkinScan site and services are protected by international copyright laws. You may not copy, reproduce, republish, upload, post, display, transmit, or frame any of these materials without prior written consent from the copyright owners, except that you may view, download, display and print a single copy of these materials on a single computer for personal, non-commercial use only, so long as:
                                <ul>
                                    <li>
                                        you do not alter or modify the materials in any way;
                                    </li>
                                    <li>
                                        you include all applicable notices and disclaimers (including copyright notices); and
                                    </li>
                                    <li>
                                        you do not use the materials in a way that suggests an association with SkinScan or an affiliated SkinScan entity. You understand and agree that title to these materials shall not pass to you or any other user.
                                    </li>
                                </ul>
                            </p>
                        </div>

                        <div className="textbox text-title text-bold">
                            Consumer rules
                        </div>
                        <div className="textbox">
                            <p className="textbox__paragraph">
                                Depending on your country, consumer protection rules may apply. We recommend you to take note of these.
                            </p>
                        </div>

                        <div className="textbox text-title text-bold">
                            Governing law & jurisdiction
                        </div>
                        <div className="textbox">
                            <p className="textbox__paragraph">
                                These terms os service and your use of the site and services are governed by and construed in accordance with the laws of Germany applicable to agreements made and to be entirely performed within Germany, without regard to its conflict of law principles.
                            </p>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}
