import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Instructions from './instructions.js';


export default class Check extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // Default states
            image: null,
            image_file: null,
            results: false,
            confirmed: false
        };

        this.onImageChange = this.onImageChange.bind(this);
        this.removeImage = this.removeImage.bind(this);
        this.submitImage = this.submitImage.bind(this);
        this.resetResults = this.resetResults.bind(this);
        this.confirmSaliencyMap = this.confirmSaliencyMap.bind(this);
    }


    componentDidMount() {
        // Track pageview
        this.props.pageview();
    }


    onImageChange(event) {
        if (event.target.files[0]) {
            var img = event.target.files[0];
            this.setState({
                image: URL.createObjectURL(img),
                image_file: event.target.files[0]
            });
        }
    }


    removeImage() {
        this.setState({
            image: null,
            image_file: null
        });
    }


    resetResults() {
        this.setState({
            image: null,
            image_file: null,
            results: false,
            confirmed: false
        });
    }


    submitImage() {
        const componentInstance = this;
        componentInstance.props.setLoading();
        // Submit image
        try {
            // Prepare data
            var formData = new FormData();
            formData.append(
                "imagefile",
                componentInstance.state.image_file
            );


            axios.all([
                axios({
                    baseURL: componentInstance.props.baseUrl,
                    withCredentials: true,
                    url: "/api/v001/checkrisk",
                    method: "post",
                    timeout: this.props.apiTimeout,
                    data: formData,
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                })
            ])
                .then(axios.spread(function (resp) {
                    var status = resp.data.status;

                    //Proceed based on status
                    if (status === true) {
                        componentInstance.props.trackEvent("Check", "Check success");
                        componentInstance.removeImage()
                        componentInstance.setState({
                            results: resp.data
                        });

                        if (resp.data.risk_level == 0) {
                            componentInstance.setState({
                                confirmed: true
                            });
                        }
                    } else {
                        // Set error
                        var name = "Server error";
                        var message = "Something went wrong. Please try again later";
                        this.props.setError(name, message);
                        // Track event
                        componentInstance.props.trackEvent("Client error", "Check_submitImage_1");
                        componentInstance.props.resetLoading();
                    }
                    componentInstance.props.resetLoading();
                }))
                .catch(err => {
                    // Set error
                    var name = "Server error";
                    var message = "Something went wrong. Please try again later";
                    this.props.setError(name, message);
                    // Track event
                    componentInstance.props.trackEvent("Client error", "Check_submitImage_2");
                    componentInstance.props.resetLoading();
                });
        } catch (error) {
            // Set error
            var name = "Server error";
            var message = "Something went wrong. Please try again later";
            this.props.setError(name, message);
            // Track event
            componentInstance.props.trackEvent("Client error", "Check_submitImage_3");
            componentInstance.props.resetLoading();
        }
    }


    confirmSaliencyMap() {
        this.setState({
            confirmed: true
        });
    }


    render() {
        return (
            <div className="content-container">
                <div className="content-wrapper">

                    <div className="section">
                        <div className="check__process">
                            <div className={"check__process__element" + (!this.state.image && !this.state.results ? " check__process__element--active" : "") + (this.state.results ? " check__process__element--done" : "")}>
                                Take picture of mole
                            </div>
                            <div className="check__process__element__arrow"></div>
                            <div className={"check__process__element" + ((this.state.image || this.state.results) && !this.state.confirmed ? " check__process__element--active" : "") + (this.state.confirmed ? " check__process__element--done" : "")}>
                                Confirm and submit picture
                            </div>
                            <div className="check__process__element__arrow"></div>
                            {/* <div className={"check__process__element check__process__element-logo" + (this.state.results ? " check__process__element-logo--active" : "") + (this.state.confirmed ? " check__process__element-logo--done" : "")}></div>
                            <div className="check__process__element__arrow"></div> */}
                            <div className={"check__process__element" + (this.state.confirmed ? " check__process__element--active" : "")}>
                                Check mole risk level
                            </div>
                        </div>
                    </div>


                    <div className="section section-check">

                        {this.state.results && this.state.confirmed &&
                            <div className="check-container">

                                {this.state.results.risk_level == 2 &&
                                    <div className="check__score check__score--high">
                                        High risk ({this.state.results.risk_score})
                                    </div>
                                }
                                {this.state.results.risk_level == 1 &&
                                    <div className="check__score check-bbox__result__score--medium">
                                        Medium risk ({this.state.results.risk_score})
                                    </div>
                                }
                                {this.state.results.risk_level == 0 &&
                                    <div className="check__score check-bbox__result__score--low">
                                        Low risk ({this.state.results.risk_score})
                                    </div>
                                }

                                {this.state.results.risk_level == 2 &&
                                    <div className="check__textbox">
                                        <p className="check__textbox__paragraph">
                                            This mole is at high risk for skin cancer.
                                        </p>
                                        <p className="check__textbox__paragraph text-bold">
                                            You should consult a medical professional.
                                        </p>
                                        <p className="check__textbox__paragraph">
                                            This is neither a diagnosis nor a definitive evaluation of whether this mole is malignant.
                                            Only a medical professional (dermatologist) is capable of providing an accurate diagnosis.
                                        </p>
                                    </div>
                                }
                                {this.state.results.risk_level == 1 &&
                                    <div className="check__textbox">
                                        <p className="check__textbox__paragraph">
                                            This mole is at medium risk for skin cancer.
                                        </p>
                                        <p className="check__textbox__paragraph text-bold">
                                            You should consult a medical professional.
                                        </p>
                                        <p className="check__textbox__paragraph">
                                            This is neither a diagnosis nor a definitive evaluation of whether this mole is malignant.
                                            Only a medical professional (dermatologist) is capable of providing an accurate diagnosis.
                                        </p>
                                    </div>
                                }
                                {this.state.results.risk_level == 0 &&
                                    <div className="check__textbox">
                                        <p className="check__textbox__paragraph">
                                            This mole is at low risk for skin cancer.
                                        </p>
                                        <p className="check__textbox__paragraph">
                                            This is not a diagnosis and does not mean that skin cancer now or in the future can be ruled out.
                                            Only a medical professional (dermatologist) is capable of providing an accurate diagnosis.
                                        </p>
                                    </div>
                                }


                                {this.state.results.risk_level != 0 &&
                                    <div className="check__buttonbox">
                                        <div className="button button-secondary" onClick={this.resetResults}>
                                            Go back
                                        </div>
                                        {/* <div className="button button-secondary" onClick={this.resetResults}>
                                            Add to account
                                        </div> */}
                                        <Link to="/account" className="button button-primary" onClick={this.resetResults}>
                                            Go to account
                                        </Link>
                                        {/* <Link to="/doctor/search" className="button button-primary" onClick={this.resetResults}>
                                            Find doctor
                                        </Link> */}
                                    </div>
                                }

                                {this.state.results.risk_level == 0 &&
                                    <div className="check__buttonbox">
                                        <div className="button button-secondary" onClick={this.resetResults}>
                                            Go back
                                        </div>
                                        {/* <div className="button button-secondary" onClick={this.resetResults}>
                                            Add to account
                                        </div> */}
                                        <Link to="/account" className="button button-primary" onClick={this.resetResults}>
                                            Go to account
                                        </Link>
                                    </div>
                                }

                                {/* <div className="check__notes">
                                    All evaluations made are medical information and by no means entail medical recommendations. SkinCheck assumes no responsibility for any evaluations made. A low risk evaluation does not mean that risk for skin cancer can be ruled out.
                                </div> */}
                            </div>
                        }


                        {this.state.results && !this.state.confirmed &&
                            <div className="check-container">

                                <div className="check__saliency-map-container">
                                    <img src={this.props.baseUrl + "/api/v001/getscansaliencymap?scan_id=" + this.state.results.file_id} className="check__saliency-map__image" alt="Saliency Map"></img>
                                </div>

                                <div className="check__textbox">
                                    Please confirm that SkinScan accurately identified your mole. Your mole - or at least parts of it - should be highlighted with a green or yellow overlay.
                                    <br />
                                    <br />
                                    If this is not the case, SkinScan could not accurately identify you mole. Please try again and follow the <Link className="text-link-inline" to="/instructions">Instructions</Link>.
                                </div>

                                <div className="check__buttonbox">
                                    <div className="button button-secondary" onClick={this.resetResults}>
                                        Go back
                                    </div>
                                    <div className="button button-primary" onClick={this.confirmSaliencyMap}>
                                        Confirm and get results
                                    </div>
                                </div>

                            </div>
                        }


                        {!this.state.results &&
                            <div className="check-container">

                                <div className="check__image-container">
                                    {this.state.image &&
                                        <div className="check__image-wrapper">
                                            <img className="check__image" src={this.state.image} />
                                            <div className="check__image-overlay"></div>
                                        </div>
                                    }

                                    {!this.state.image &&
                                        <label for="imageFile">
                                            <div className="check__image__icon">+</div>
                                        </label>
                                    }

                                    <input
                                        type="file"
                                        id="imageFile"
                                        name="imageFile"
                                        className="hidden"
                                        accept="image/png, image/jpeg, image/jpg"
                                        onChange={(event) => {
                                            this.onImageChange(event);
                                        }}
                                    />
                                </div>

                                {!this.state.image &&
                                    <div className="check__textbox check__textbox--center">
                                        Select a picture of your mole
                                    </div>
                                }

                                {this.state.image &&
                                    <div className="check__textbox">
                                        Please confirm the picture of your mole has been taken in accordance with the <Link className="text-link-inline" to="/instructions">Instructions</Link>. 
                                    </div>
                                }

                                {this.state.image &&
                                    <div className="check__buttonbox">
                                        <div className="button button-secondary" onClick={this.removeImage}>
                                            Go back
                                        </div>
                                        <div className="button button-primary" onClick={this.submitImage}>
                                            Confirm and submit
                                        </div>
                                    </div>
                                }

                                {/* <div className="check__notes">
                                All evaluations made are medical information and by no means entail medical recommendations. SkinCheck assumes no responsibility for any evaluations made. A low risk evaluation does not mean that risk for skin cancer can be ruled out.
                            </div> */}
                            </div>
                        }


                    </div>


                    {/* {this.state.image && !this.state.results &&
                        <Instructions />
                    } */}

                </div>
            </div>
        )
    }
}
