import React, { Component } from 'react';
import Hero from './hero.js';
import References from './references.js';
import Working from './working.js';
import Functioning from './functioning.js';

export default class Eventoverview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // Default states
        };
    }


    componentDidMount() {
        // Track pageview
        this.props.pageview();
    }


    render() {
        return (
            <div className="">
                <div className="content-container">
                    <Hero 
                        login={this.props.login}
                    />
                </div>
                <div className="content-container">
                    <div className="content-wrapper">
                        <Working />
                    </div>
                </div>
                <References />
                <div className="content-container">
                    <div className="content-wrapper">
                        <Functioning />
                    </div>
                </div>
            </div>
        )
    }
}
