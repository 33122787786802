import React, { Component } from 'react';
import axios from 'axios';
import FormEmail from '../forms/forminput_email.js';
import FormPassword from '../forms/forminput_password.js';


export default class Account extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // Default states
            form: {},
            loginError: ""
        };

        this.currentDatetime = new Date();

        this.setFormValue = this.setFormValue.bind(this);
        this.login = this.login.bind(this);
    }


    componentDidMount() {
        // Track pageview
        this.props.pageview();
    }


    setFormValue(label, value) {
        // Update filters
        var form = this.state.form;
        form[label] = value;

        // Update state
        this.setState({
            form: form
        });
    }


    login() {
        const componentInstance = this;
        componentInstance.props.setLoading();
        // Post event data
        try {
            axios.all([
                axios({
                    baseURL: this.props.baseUrl,
                    withCredentials: true,
                    url: "/api/v001/login",
                    method: "post",
                    timeout: this.props.apiTimeout,
                    data: {
                        email: this.state.form.email,
                        password: this.state.form.password
                    }
                })
            ])
            .then(axios.spread(function (resp) {
                var status = resp.data;

                //Proceed based on status
                if (status.status == true) {
                    componentInstance.props.trackEvent("Login", "Login success");
                    componentInstance.props.setLogin(true, "Email", "First", "Last");
                } else {
                    componentInstance.setState({
                        loginError: "Wrong email or password"
                    });
                }
                componentInstance.props.resetLoading();
            }))
            .catch(err => {
                // Set error
                var name = "Server error";
                var message = "Something went wrong. Please try again later";
                this.props.setError(name, message);
                // Track event
                componentInstance.props.trackEvent("Client error", "Login_login_1");
                componentInstance.props.resetLoading();
            });
        } catch (error) {
            componentInstance.setState({
                loginError: "There was an error with your request. Please try again later."
            });

            // Set error
            var name = "Server error";
            var message = "Something went wrong. Please try again later";
            this.props.setError(name, message);
            // Track event
            componentInstance.props.trackEvent("Client error", "Login_login_2");
            componentInstance.props.resetLoading();
        }
    }


    render() {
        return (
            <div className="content-container">
                <div className="content-wrapper">
                    <div className="section section-form">
                        <div className="form-container">
                            <div className="form__element form__title">
                                Login
                            </div>

                            <FormEmail
                                name={"email"}
                                label={"Email"}
                                setFormValue={this.setFormValue.bind(this)}
                                required={true}
                                note={"Your email that you used to register"}
                            />

                            <FormPassword
                                name={"password"}
                                label={"Password"}
                                setFormValue={this.setFormValue.bind(this)}
                                required={true}
                                note={"Your password"}
                            />

                            {this.state.loginError &&
                                <div className="form__element">
                                    {this.state.loginError}
                                </div>
                            }

                            <div className="form__buttons-container">
                                <div onClick={this.login} className="form__buttons__button button button--margin button-primary">
                                    Login
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
