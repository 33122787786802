import React, { Component } from 'react';

export default class Cookie extends Component {


    componentDidMount() {
        // Track pageview
        this.props.pageview();
    }

    
    render() {
        return (
            <div className="content-container">
                <div className="content-wrapper">
                    <div className="section">
                        <div className="textbox text-title text-bold">
                            Cookie policy
                        </div>
                        <div className="textbox">
                            Last updated June 15, 2022
                        </div>

                        <div className="textbox text-title text-bold">
                            Title
                        </div>
                        <div className="textbox">
                            <p className="textbox__paragraph">
                                Text
                            </p>
                            <p className="textbox__paragraph">
                                Text
                            </p>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}
