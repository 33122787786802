import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import Overview from './overview.js';
import Scans from './scans.js';
import Scan from './scan.js';


export default class Account extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // Default states
            login: this.props.login
        };
    }


    componentDidMount() {
        // Track pageview
        this.props.pageview();
    }


    render() {
        return (
            <div className="content-container">
                <div className="content-wrapper">

                    {this.props.login &&
                        <Route
                            exact={true}
                            path="/account"
                            render={
                                (props) => (
                                    <Overview
                                        /* setError={this.props.setError} */
                                        baseUrl={this.props.baseUrl}
                                        login={this.props.login}
                                        {...props}
                                    />)
                            }
                        />
                    }

                    {true &&
                        <Route
                            exact={false}
                            path="/account/scans"
                            render={
                                (props) => (
                                    <Scans
                                        /* setError={this.props.setError} */
                                        baseUrl={this.props.baseUrl}
                                        login={this.props.login}
                                        pageview={this.props.pageview}
                                        trackEvent={this.props.trackEvent}
                                        setLoading={this.props.setLoading}
                                        resetLoading={this.props.resetLoading}
                                        setError={this.props.setError}
                                        resetError={this.props.resetError}
                                        {...props}
                                    />)
                            }
                        />
                    }

                    {true &&
                        <Route
                            exact={false}
                            path="/account/scan/:id"
                            render={
                                (props) => (
                                    <Scan
                                        /* setError={this.props.setError} */
                                        baseUrl={this.props.baseUrl}
                                        login={this.props.login}
                                        pageview={this.props.pageview}
                                        trackEvent={this.props.trackEvent}
                                        setLoading={this.props.setLoading}
                                        resetLoading={this.props.resetLoading}
                                        setError={this.props.setError}
                                        resetError={this.props.resetError}
                                        {...props}
                                    />)
                            }
                        />
                    }

                </div>
            </div>
        )
    }
}
