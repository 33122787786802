import React, { Component } from 'react';

export default class Loading extends Component {
    render() {
        return (
            <div className="loading">
                <div className="loading__animation"></div>
                <div className="loading__textbox">
                    Loading ...
                </div>
            </div>
        )
    }
}
