import React, { Component } from 'react';

export default class Cookieconsent extends Component {
    render() {
        return (
            <div className="cookiebanner">
                <div className="cookiebanner__wrapper">
                    <div className="cookiebanner__container">
                        <div className="cookiebanner__title">
                            <h3 className="textbox textbox--light text-title text-bold text-light">
                                Select your cookie preferences
                            </h3>
                        </div>
                        <div className="cookiebanner__content">

                            <p className="textbox textbox--light">
                            We use cookies and similar tools to enhance your experience, provide our services, deliver relevant advertising, and make improvements. Approved third parties also use these tools to help us deliver advertising and provide certain site features.    
                            </p>

                        </div>
                        <div className="cookiebanner__buttoncontainer">
                            <div className="button button-primary--light" onClick={this.props.acceptCookies.bind(this)}>
                                Accept cookies
                            </div>
                            <div className="button button-secondary--light" onClick={this.props.declineCookies.bind(this)}>
                                Only use necessary cookies
                            </div>
                        </div>
                    
                    </div>
                </div>
            </div>
        )
    }
}
