import React, { Component } from 'react';


export default class Functioning extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // Default states
        };
    }


    componentDidMount() {
    }


    render() {
        return (
            <div className="content-container content-container--primary">
                <div className="content-wrapper">
                    <div className="section section-logos">
                        <div className="logos__element logos__element--mit"></div>
                        <div className="logos__element logos__element--harvard"></div>
                        <div className="logos__element logos__element--sandbox"></div>
                        <div className="logos__element logos__element--ilab"></div>
                    </div>
                </div>
            </div>
        )
    }
}
