import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { setSessionStorage, getSessionStorage, formatDatetime, formatDate } from '../../app_functions.js';

export default class Scan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // Default states
            scan_id: this.props.match.params.id,
            data: false
        };

        this.getCheckData = this.getCheckData.bind(this);
    }


    componentDidMount() {
        // Get checks data and set to state
        if (!this.state.data) {
            this.getCheckData();
        }
    }


    getCheckData() {
        try {
            var data = getSessionStorage("checks_data");
            data = JSON.parse(data);

            // Filter scan data
            var result = data.filter(d => d.file_name === this.state.scan_id)[0];

            // Set scan data to state
            this.setState({
                data: result
            });
        } catch (error) {
            // Set error
            var name = "Server error";
            var message = "Something went wrong. Please try again later";
            this.props.setError(name, message);
            // Track event
            this.props.trackEvent("Client error", "Scan_getCheck_1");
            this.props.resetLoading();
        }
    }



    render() {
        return (
            <div className="section">
                {this.state.data.timestamp &&
                    <div className="textbox text-title text-bold">
                        Your scan from {formatDate(this.state.data.timestamp)}
                    </div>
                }

                {this.state.scan_id &&

                    <div className="scan-container">
                        {this.state.data.risk_level === 0 &&
                            <div className="scan__summary">
                                This mole is at low risk for skin cancer. If in doubt, you should consult a doctor. 
                            </div>
                        }
                        {this.state.data.risk_level === 1 &&
                            <div className="scan__summary">
                                This mole is at <span className="text-bold text-color-secondary">medium risk</span> for skin cancer. You should consult a doctor.
                            </div>
                        }
                        {this.state.data.risk_level === 2 &&
                            <div className="scan__summary">
                                This mole is at <span className="text-bold text-color-secondary">high risk</span> for skin cancer. You should consult a doctor.
                            </div>
                        }

                        <div className="scan__details">

                            {this.state.data.risk_level >= 0 &&
                                <div className="scan__details__label">
                                    <p className="scan__details__label__label">
                                        Your risk level
                                    </p>
                                </div>
                            }
                            {this.state.data.risk_level >= 0 &&
                                <div className="scan__details__value">
                                    {this.state.data.risk_level === 0 &&
                                        <p>Low</p>
                                    }
                                    {this.state.data.risk_level === 1 &&
                                        <p>Medium</p>
                                    }
                                    {this.state.data.risk_level === 2 &&
                                        <p>High</p>
                                    }
                                </div>
                            }

                            {this.state.data.risk_type &&
                                <div className="scan__details__label">
                                    <p className="scan__details__label__label">
                                        Type of skin cancer
                                    </p>
                                </div>
                            }
                            {this.state.data.risk_type &&
                                <div className="scan__details__value">
                                    Melanoma
                                </div>
                            }

                            {this.state.data.timestamp &&
                                <div className="scan__details__label">
                                    <p className="scan__details__label__label">
                                        Date and time of scan
                                    </p>
                                </div>
                            }
                            {this.state.data.timestamp &&
                                <div className="scan__details__value">
                                    {formatDatetime(this.state.data.timestamp)}
                                </div>
                            }

                            <div className="scan__details__label">
                                <p className="scan__details__label__label">
                                    Your mole
                                </p>
                                <p className="scan__details__label__description">
                                    This is the image that was used to evaluate your mole.
                                </p>
                            </div>
                            <div className="scan__details__value">
                                <img src={this.props.baseUrl + "/api/v001/getscanimage?scan_id=" + this.state.scan_id} className="scan__image" alt="Mole" width="500"></img>
                            </div>

                            <div className="scan__details__label">
                                <p className="scan__details__label__label">
                                    Saliency map of your mole
                                </p>
                                <p className="scan__details__label__description">
                                    The yellow-green overlay shows what parts of the image were particularly important in determining your risk level.
                                </p>
                            </div>
                            <div className="scan__details__value">
                                <img src={this.props.baseUrl + "/api/v001/getscansaliencymap?scan_id=" + this.state.scan_id} className="scan__image" alt="Saliency map" width="500"></img>
                            </div>

                        </div>

                        {this.state.data.risk_level === 0 &&
                            <div className="scan__legal-notes">
                                This is not a diagnosis and does not mean that skin cancer now or in the future can be ruled out.
                                Only a medical professional (dermatologist) is capable of providing an accurate diagnosis.
                            </div>
                        }

                        {this.state.data.risk_level === 1 &&
                            <div className="scan__legal-notes">
                                This is neither a diagnosis nor a definitive evaluation of whether this mole is malignant.
                                Only a medical professional (dermatologist) is capable of providing an accurate diagnosis.
                            </div>
                        }

                        {this.state.data.risk_level === 2 &&
                            <div className="scan__legal-notes">
                                This is neither a diagnosis nor a definitive evaluation of whether this mole is malignant.
                                Only a medical professional (dermatologist) is capable of providing an accurate diagnosis.
                            </div>
                        }

                        <div className="scan__buttonbox">
                            <Link to="/account/scans" className="button button-secondary">
                                Go back
                            </Link>
                            {/* <Link to="/account/editscan" className="button button-secondary">
                                Update data
                            </Link> */}
                            {/* {this.state.data.risk_level != 0 &&
                                < Link to="/doctor/search" className="button button-primary">
                                    Find doctor
                                </Link>
                            } */}
                        </div>

                    </div>
                }


                {
                    !this.state.scan_id &&
                    <div className="textbox">
                        Something went wrong. Please try again later.
                    </div>
                }

            </div >
        )
    }
}
