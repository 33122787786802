import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { setSessionStorage, getSessionStorage } from './app_functions.js';
import ReactGA from 'react-ga';
import './app.css';

import Header from './components/header/header.js';
import Footer from './components/footer/footer.js';
import Home from './components/home/home.js';
import Login from './components/login/login.js';
import Account from './components/account/account.js';
import Check from './components/check/check.js';
import Instructions from './components/check/instructions.js';
import Working from './components/home/working.js';
import Terms from './components/legal/terms.js';
import Privacy from './components/legal/privacy.js';
import Cookie from './components/legal/cookie.js';
import Cookieconsent from './components/legal/cookieconsent.js';
import LoadingAnimation from './components/loading/loading.js';
import Error from './components/error/error.js';



class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Default states
      error: {},
      loading: true,
      login: false,
      user_email: "",
      user_fname: "",
      user_lname: "",
      cookieConsent: false,
      displayCookieBanner: true
    };

    // Define environment variables
    //this.baseUrl = "http://127.0.0.1:5000";
    this.baseUrl = "https://skinscan-webapp-container-service.lpiqhk9hiqi96.eu-central-1.cs.amazonlightsail.com";
    this.apiTimeout = 30000;

    // Define class methods
    this.setLogin = this.setLogin.bind(this);
    this.setLogout = this.setLogout.bind(this);
  }


  componentDidMount() {
    window.scrollTo(0, 0);

    var login = getSessionStorage("login");
    var user_email = getSessionStorage("user_email");
    var user_fname = getSessionStorage("user_fname");
    var user_lname = getSessionStorage("user_lname");

    this.setState({
      login: login,
      user_email: user_email,
      user_fname: user_fname,
      user_lname: user_lname
    });

    // Handle cookie settings
    var cookie_consent_set = (getSessionStorage("cookie_consent_set") == "true" ? true : false);
    var cookie_consent = (getSessionStorage("cookie_consent") == "true" ? true : false);

    if (cookie_consent_set) {
      this.setState({
        displayCookieBanner: false
      })
      if (cookie_consent) {
        this.acceptCookies();
      }
    }

    // Set state to not loading
    this.setState({
      loading: false
    });
  }


  async acceptCookies() {
    this.setState({
      cookieConsent: true,
      displayCookieBanner: false
    })

    setSessionStorage("cookie_consent_set", true);
    setSessionStorage("cookie_consent", true);

    await this.initializeReactGA();
    this.pageview();
  }


  declineCookies() {
    this.setState({
      cookieConsent: false,
      displayCookieBanner: false
    })

    setSessionStorage("cookie_consent_set", true);
    setSessionStorage("cookie_consent", false);

    this.initializeReactGA();
  }


  initializeReactGA() {
    ReactGA.initialize('UA-232132613-1', {
      'cookieDomain': 'auto',
      'debug': true
    });
    ReactGA.set({ 
      anonymizeIp: true 
    });
  }


  pageview() {
    try {
      if (this.state.cookieConsent) {
        ReactGA.pageview(window.location.pathname + window.location.search);
      }
    } catch(err) {
      // console.log(err);
    }
  }


  trackEvent(category, action) {
    try {
      if (this.state.cookieConsent) {
        ReactGA.event({
          category: category,
          action: action
        });
      }
    } catch(err) {
      // console.log(err);
    }
  }


  setLogin(status, email, fname, lname) {
    this.setState({
      login: status,
      user_email: email,
      user_fname: fname,
      user_lname: lname
    })
    setSessionStorage("login", true);
    setSessionStorage("user_email", email);
    setSessionStorage("user_fname", fname);
    setSessionStorage("user_lname", lname);

    // Track event
    this.trackEvent("Login", "Manual User Login");

    // Redirect
    this.resetLoading();
    this.props.history.push('/account');
  }


  setLogout() {
    this.setState({
      login: false,
      user_fname: "",
      user_lname: ""
    })
    setSessionStorage("login", false);
    setSessionStorage("user_fname", "");
    setSessionStorage("user_lname", "");

    // Track event
    this.trackEvent("Login", "Manual User Logout");
  }


  setError(title, message) {
    var error = {};
    error.title = title;
    error.message = message;
    this.setState({
      error: error
    })
  }


  resetError() {
    this.setState({
        error: {}
    })
  }


  setLoading() {
    this.setState({
      loading: true
    })
  }


  resetLoading() {
    this.setState({
      loading: false
    })
  }


  render() {
    return (
      <div>

        <Route
          exact={false}
          path='/'
          render={
            (props) => (
              <Header
                /* setError={this.props.setError} */
                setLogout={this.setLogout}
                login={this.state.login}
              />)
          }
        />

        <Route
          exact={true}
          path='/'
          render={
            (props) => (
              <Home
                /* setError={this.props.setError} */
                login={this.state.login}
                pageview={this.pageview.bind(this)}
                {...props}
              />)
          }
        />

        <Route
          exact={true}
          path='/login'
          render={
            (props) => (
              <Login
                /* setError={this.props.setError} */
                setLogin={this.setLogin}
                baseUrl={this.baseUrl}
                apiTimeout={this.apiTimeout}
                login={this.state.login}
                pageview={this.pageview.bind(this)}
                trackEvent={this.trackEvent.bind(this)}
                setLoading={this.setLoading.bind(this)}
                resetLoading={this.resetLoading.bind(this)}
                setError={this.setError.bind(this)}
                resetError={this.resetError.bind(this)}
                {...props}
              />)
          }
        />

        {this.state.login &&
          <Route
            exact={false}
            path='/account'
            render={
              (props) => (
                <Account
                  /* setError={this.props.setError} */
                  baseUrl={this.baseUrl}
                  apiTimeout={this.apiTimeout}
                  login={this.state.login}
                  pageview={this.pageview.bind(this)}
                  trackEvent={this.trackEvent.bind(this)}
                  setLoading={this.setLoading.bind(this)}
                  resetLoading={this.resetLoading.bind(this)}
                  setError={this.setError.bind(this)}
                  resetError={this.resetError.bind(this)}
                  {...props}
                />)
            }
          />
        }

        {!this.state.login &&
          <Route
            exact={false}
            path='/account'
            render={
              (props) => (
                <Login
                  /* setError={this.props.setError} */
                  setLogin={this.setLogin}
                  baseUrl={this.baseUrl}
                  apiTimeout={this.apiTimeout}
                  login={this.state.login}
                  pageview={this.pageview.bind(this)}
                  trackEvent={this.trackEvent.bind(this)}
                  setLoading={this.setLoading.bind(this)}
                  resetLoading={this.resetLoading.bind(this)}
                  setError={this.setError.bind(this)}
                  resetError={this.resetError.bind(this)}
                  {...props}
                />)
            }
          />
        }

        {this.state.login &&
          <Route
            exact={true}
            path='/check'
            render={
              (props) => (
                <Check
                  /* setError={this.props.setError} */
                  baseUrl={this.baseUrl}
                  apiTimeout={this.apiTimeout}
                  login={this.state.login}
                  pageview={this.pageview.bind(this)}
                  trackEvent={this.trackEvent.bind(this)}
                  setLoading={this.setLoading.bind(this)}
                  resetLoading={this.resetLoading.bind(this)}
                  setError={this.setError.bind(this)}
                  resetError={this.resetError.bind(this)}
                  {...props}
                />)
            }
          />
        }

        {!this.state.login &&
          <Route
            exact={true}
            path='/check'
            render={
              (props) => (
                <Login
                  /* setError={this.props.setError} */
                  setLogin={this.setLogin}
                  baseUrl={this.baseUrl}
                  apiTimeout={this.apiTimeout}
                  login={this.state.login}
                  pageview={this.pageview.bind(this)}
                  trackEvent={this.trackEvent.bind(this)}
                  setLoading={this.setLoading.bind(this)}
                  resetLoading={this.resetLoading.bind(this)}
                  setError={this.setError.bind(this)}
                  resetError={this.resetError.bind(this)}
                  {...props}
                />)
            }
          />
        }

        <Route
          exact={true}
          path='/working'
          render={
            (props) => (
              <Working
              /* setError={this.props.setError} */
                pageview={this.pageview.bind(this)}
                {...props}
              />)
          }
        />

        <Route
          exact={true}
          path='/instructions'
          render={
            (props) => (
              <Instructions
              /* setError={this.props.setError} */
                pageview={this.pageview.bind(this)}
                {...props}
              />)
          }
        />

        <Route
          exact={true}
          path='/cookies'
          render={
            (props) => (
              <Cookie
              /* setError={this.props.setError} */
                pageview={this.pageview.bind(this)}
                {...props}
              />)
          }
        />

        <Route
          exact={true}
          path='/terms'
          render={
            (props) => (
              <Terms
              /* setError={this.props.setError} */
                pageview={this.pageview.bind(this)}
                {...props}
              />)
          }
        />

        <Route
          exact={true}
          path='/privacy'
          render={
            (props) => (
              <Privacy
              /* setError={this.props.setError} */
                pageview={this.pageview.bind(this)}
                {...props}
              />)
          }
        />

        {this.state.displayCookieBanner &&
          <Route 
            exact={false} 
            path="/" 
            render={
              (props) => (
              <Cookieconsent
                pageview={this.pageview.bind(this)}
                acceptCookies={this.acceptCookies.bind(this)}
                declineCookies={this.declineCookies.bind(this)}
                {...props}
              />)
            }
          />
        }

        {this.state.error.title &&
          <Route 
            exact={false} 
            path="/" 
            render={
              (props) => (
              <Error
                title={this.state.error.title}
                message={this.state.error.message}
                resetError={this.resetError.bind(this)}
                {...props}
              />)
            }
          />
        }


        {/* {this.state.error.message &&
        <Popup 
          title={this.state.error.title}
          content={this.state.error.message}
          closeHandler={this.resetError.bind(this)}
        />
        } */}


        {this.state.loading &&
        <LoadingAnimation />
        }

        <Footer />

      </div>
    );
  }
}

export default withRouter(App);