import React, { Component } from 'react';


export default class Footer extends Component {
    render() {
        return (
            <div className="error">
                <div className="error__wrapper">
                    <div className="error__content">
                        <div className="error__content__title">
                            {this.props.title}
                        </div>
                        <div className="error__content__message">
                            {this.props.message}
                        </div>
                    </div>
                    <div className="error__close" onClick={this.props.resetError}></div>
                </div>
            </div>
        )
    }
}
