// Set value to localStorage
export function setLocalStorage(key, value) {
  localStorage.setItem(key, value);
}
  
// Get value from localStorage
export function getLocalStorage(key) {
  var value = "";
  if (value = localStorage.getItem(key)) {
    return value;
  }
  return false;
}


// Set value to sessionStorage
export function setSessionStorage(key, value) {
  sessionStorage.setItem(key, value);
}

// Get value from sessionStorage
export function getSessionStorage(key) {
  var value = "";
  if (value = sessionStorage.getItem(key)) {
    return value;
  }
  return false;
}


// Format UTC datetime to locale datetime
export function formatDatetime(timestamp) {
    var date = new Date(timestamp.slice(0, 19) + " UTC");
    var datetime = date.toLocaleDateString() + ", " + date.toLocaleTimeString([], {timeStyle: 'short'})
    return datetime;
}


// Format UTC datetime to locale date
export function formatDate(timestamp) {
    var date = new Date(timestamp.slice(0, 19) + " UTC");
    var datetime = date.toLocaleDateString()
    return datetime;
}