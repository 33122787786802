import React, { Component } from 'react';
import { Link } from 'react-router-dom';


export default class Working extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // Default states
        };
    }


    componentDidMount() {
    }


    render() {
        return (
            <div className="section">
                <div className="textbox text-title text-bold text-color-primary">
                    How it works
                </div>
                <div className="process-wrapper">
                    <div className="process__element">
                        <div className="process__element__icon process__element__icon--1"></div>
                        <div className="process__element__textbox">
                            Take a <span className="text-bold text-color-primary">picture of your mole</span> and upload it to SkinScan.
                        </div>
                    </div>
                    <div className="process__arrow"></div>
                    <div className="process__element">
                        <div className="process__element__icon process__element__icon--2"></div>
                        <div className="process__element__textbox">
                            SkinScan evaluates the <span className="text-bold text-color-primary">risk of skin cancer</span> for your mole using machine learning algorithms. 
                        </div>
                    </div>
                    <div className="process__arrow"></div>
                    <div className="process__element">
                        <div className="process__element__icon process__element__icon--3"></div>
                        <div className="process__element__textbox">
                            Based on the estimated risk level you might decide to <span className="text-bold text-color-primary">consult a doctor</span> and seek medical advice. 
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
