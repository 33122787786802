import React, { Component } from 'react';
import { Link } from 'react-router-dom';


export default class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // Default states
            dropdown: false
        };

        this.logout = this.logout.bind(this);
        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.closeDropdown = this.closeDropdown.bind(this);
    }


    componentDidUpdate() {
    }


    logout() {
        this.props.setLogout();
        this.closeDropdown();
    }


    toggleDropdown() {
        this.setState({
            dropdown: !this.state.dropdown
        });
    }


    closeDropdown() {
        this.setState({
            dropdown: false
        });
    }


    render() {
        return (
            <div className="header">
                <div className="header-wrapper">
                    <div className="header__navbar header__navbar--left">
                        <Link to="/" className="header__navbar__logo"></Link>
                    </div>

                    <div className={"header-dropdown" + (this.state.dropdown ? " header-dropdown--active" : " header-dropdown--no-active")} onClick={this.toggleDropdown}></div>

                    <div className={"header__navbar header__navbar--right" + (this.state.dropdown ? " header__navbar--right--active" : "")}>
                        {this.props.login &&
                            <Link to="/check" className={"header__navbar__element"} onClick={this.closeDropdown}>
                                Check now
                            </Link>
                        }

                        {!this.props.login &&
                            <Link to="/login" className={"header__navbar__element"} onClick={this.closeDropdown}>
                                Login
                            </Link>
                        }

                        {this.props.login &&
                            <Link to="/account" className={"header__navbar__element"} onClick={this.closeDropdown}>
                                Account
                            </Link>
                        }

                        {this.props.login &&
                            <Link to="#" className={"header__navbar__element"} onClick={this.logout}>
                                Logout
                            </Link>
                        }

                    </div>

                </div>
            </div>
        )
    }
}
