import React, { Component } from 'react';
import { Link } from 'react-router-dom';


export default class Hero extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // Default states
        };
    }


    componentDidMount() {
    }


    render() {
        return (
            <div className="content-container hero-container">
                <div className="hero-wrapper">

                    <div className="hero__title text-bold text-title text-color-light">
                        Scan Your Moles for Skin Cancer
                    </div>
                    <div className="hero__textbox text-color-light">
                        Avoid skin cancer by regularly checking your moles. Upload a picture of your mole to check whether you are at risk and should visit a doctor.
                    </div>

                    {this.props.login && 
                    <div className="hero__buttonbox">
                        <Link to="/check" className="button button-primary--light">
                            Check now
                        </Link>
                    </div>
                    }

                    {!this.props.login && 
                    <div className="hero__buttonbox textbox text-color-light">
                        Our service will launch around fall 2022. Check back soon to benefit from early user discounts. 
                    </div>
                    }

                    <div className="hero__illustration">
                        <div className="hero__illustration__phone"></div>
                        <div className="hero__illustration__background-overlay"></div>
                        <div className="hero__illustration__background"></div>
                    </div>
                    <div className="hero__note text-color-light">
{/*                         Any evaluations made by SkinCheck must be treated as estimations and do not entail or correspond to medical recommendations. SkinCheck is not medically certified and does not replace a doctor consultation.
 */}                    </div>

                </div>
            </div>
        )
    }
}
