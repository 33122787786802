import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as IconNewScan } from '../../media/icons/new_scan.svg';
import { ReactComponent as IconFiles } from '../../media/icons/files.svg';


export default class Account extends Component {
    render() {
        return (
            <div className="section">
                <div className="textbox text-title text-bold">
                    Welcome back, Oskar!
                </div>
                <div className="account__buttonbox">
                    <Link to="/check" className="account__buttonbox__button">
                        <div className="account__buttonbox__button__icon">
                            <IconNewScan className="account__buttonbox__button__icon--inner" />
                        </div>
                        <div className="account__buttonbox__button__label">
                            New scan
                        </div>
                    </Link>
                    <Link to="/account/scans" className="account__buttonbox__button">
                        <div className="account__buttonbox__button__icon">
                            <IconFiles className="account__buttonbox__button__icon--inner" />
                        </div>
                        <div className="account__buttonbox__button__label">
                            Your scans
                        </div>
                    </Link>
                    {/* <Link to="/account/reminders" className="account__buttonbox__button">
                                Reminders
                            </Link> */}
                    {/* <Link to="/account/personal" className="account__buttonbox__button">
                                Personal information
                            </Link> */}
                    {/* <Link to="/account/subscription" className="account__buttonbox__button">
                                Subscription and payment
                            </Link> */}
                </div>
            </div>
        )
    }
}
