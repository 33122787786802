import React, { Component } from 'react';


export default class Functioning extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // Default states
        };
    }


    componentDidMount() {
    }


    render() {
        return (
            <div className="section">
                <div className="textbox text-title text-bold text-color-primary">
                    How SkinScan evaluates risk levels
                </div>
                <div className="functioning-wrapper">
                    <div className="functioning__element">
                        <div className="functioning__element__icon functioning__element__icon--1"></div>
                        <div className="functioning__element__textbox">
                            SkinScan uses <span className="text-bold text-color-primary">advanced machine learning (AI) technologies</span> that proved successful in various experiments. These technologies are particularly good at evaluating small patterns in images of moles. 
                        </div>
                    </div>
                    <div className="functioning__element">
                        <div className="functioning__element__icon functioning__element__icon--2"></div>
                        <div className="functioning__element__textbox">
                            The AI algorithm has <span className="text-bold text-color-primary">learned from more than 50,000 images of moles</span> to detect whether a mole might be at risk for skin cancer. The algorithm applies this knowledge when evaluating new moles. 
                        </div>
                    </div>
                    <div className="functioning__element">
                        <div className="functioning__element__icon functioning__element__icon--3"></div>
                        <div className="functioning__element__textbox">
                            The AI algorithm <span className="text-bold text-color-primary">detects even very small and subtle patterns</span> that can hardly be recognized with human eyes alone. Based on the presence or absence of certain patterns, SkinScan estimates the risk of skin cancer. 
                        </div>
                    </div>
                    <div className="functioning__element">
                        <div className="functioning__element__icon functioning__element__icon--4"></div>
                        <div className="functioning__element__textbox">
                            SkinScan health assessments can help <span className="text-bold text-color-primary">guide consultations with medical professional</span>. By creating skin health awareness and asking more tailored questions, you can take ownership of your skin. 
                            {/* In a experiment of over 10,000 moles, <span className="text-bold text-color-primary">X% were correctly classified as being malignent or benign (non-malignent)</span>. Specifically, X% of 'high risk' classifications were actually malignent and X% of 'low risk' classifications were actually benign.  */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
