import React, { Component } from 'react';
import { Link } from 'react-router-dom';


export default class Footer extends Component {
    render() {
        return (
            <div className="footer">
                <div className="footer-wrapper">

                    {/* <div className="footer__column">
                        <div className="footer__column__element text-bold text-title">
                            About
                        </div>
                        <div className="footer__column__element text-box text-justify">
                            <p>
                                SkinCheck let's you check your moles for risk of skin cancer. We help you understand what moles pose a risk of developing into skin cancer.
                            </p>
                        </div>
                    </div> */}

                    <div className="footer__column">
                        <div className="footer__column__element text-bold text-title">
                            SkinScan
                        </div>
                        {/* <div className="footer__column__element text-box">
                            <Link to="/research">
                                Research
                            </Link>
                        </div> */}
                        <div className="footer__column__element text-box">
                            <Link to="/terms">
                                Terms of service
                            </Link>
                        </div>
                        <div className="footer__column__element text-box">
                            <Link to="/privacy">
                                Privacy policy
                            </Link>
                        </div>
                        <div className="footer__column__element text-box">
                            <Link to="/cookies">
                                Cookie policy
                            </Link>
                        </div>
                    </div>

                    <div className="footer__column">
                        <div className="footer__column__element text-bold text-title">
                            For Patients
                        </div>
                        <div className="footer__column__element text-box">
                            <Link to="/check">
                                Check now
                            </Link>
                        </div>
                        <div className="footer__column__element text-box">
                            <Link to="/account">
                                Account
                            </Link>
                        </div>
                        {/* <div className="footer__column__element text-box">
                            <Link to="/referral">
                                Referral program
                            </Link>
                        </div> */}
                        {/* <div className="footer__column__element text-box">
                            <Link to="/instructions">
                                Instructions
                            </Link>
                        </div> */}
                        {/* <div className="footer__column__element text-box">
                            <Link to="/faq">
                                FAQ
                            </Link>
                        </div> */}
                        {/* <div className="footer__column__element text-box">
                            <Link to="/contact">
                                Contact
                            </Link>
                        </div> */}
                    </div>

                    <div className="footer__column">
                        <div className="footer__column__element text-bold text-title">
                            For Partners
                        </div>
                        {/* <div className="footer__column__element text-box">
                            <Link to="/partnerships/dermatologist">
                                Dermatologist partnerships
                            </Link>
                        </div> */}
                        {/* <div className="footer__column__element text-box">
                            <Link to="/partnerships/company">
                                Company partnerships
                            </Link>
                        </div> */}
                    </div>

                    <div className="footer__column">
                        <div className="footer__column__element text-bold text-title">
                            Follow us
                        </div>
                        <div className="footer__column__element text-box">
                            <a href="https://www.facebook.com/skinscanai">
                                Facebook
                            </a>
                        </div>
                        <div className="footer__column__element text-box">
                            <a href="https://www.linkedin.com/company/skinscan-ai/">
                                LinkedIn
                            </a>
                        </div>
                    </div>

                    <div className="footer__note">
                        <p>
                            Copyright © 2022 SkinScan. All Rights Reserved.
                        </p>
                    </div>

                </div>
            </div>
        )
    }
}
