import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { setSessionStorage, getSessionStorage, formatDatetime } from '../../app_functions.js';

export default class Scans extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // Default states
            data: []
        };

        this.getChecks = this.getChecks.bind(this);
    }


    componentDidMount() {
        // Get checks data and set to state
        this.getChecks();
        this.props.resetLoading();
    }


    getChecks() {
        const componentInstance = this;
        componentInstance.props.setLoading();

        // Try to get data from local storage
        try {
            var data = getSessionStorage("checks_data");
            data = JSON.parse(data);
            
            // Check if data in local storage exists
            if (data.length >= 1) {
                componentInstance.setState({
                    data: data
                });
                return true;
            }
        } catch (error) {
            // Set error
            var name = "Server error";
            var message = "Something went wrong. Please try again later";
            componentInstance.props.setError(name, message);
            // Track event
            componentInstance.props.trackEvent("Client error", "Scans_getChecks_1");
            componentInstance.props.resetLoading();
        }

        // Post event data
        try {
            axios.all([
                axios({
                    baseURL: this.props.baseUrl,
                    withCredentials: true,
                    url: "/api/v001/getchecks",
                    method: "post",
                    timeout: this.props.apiTimeout
                })
            ])
                .then(axios.spread(function (resp) {
                    var data = resp.data;

                    //Proceed based on status
                    if (data) {
                        componentInstance.setState({
                            data: data
                        });
                        setSessionStorage("checks_data", JSON.stringify(data));
                        return true;
                    } else {
                        // Set error
                        var name = "Server error";
                        var message = "Something went wrong. Please try again later";
                        this.props.setError(name, message);
                        // Track event
                        componentInstance.props.trackEvent("Client error", "Scans_getChecks_2");
                        componentInstance.props.resetLoading();
                    }
                    componentInstance.props.resetLoading();
                }))
                .catch(error => {
                    // Set error
                    var name = "Server error";
                    var message = "Something went wrong. Please try again later";
                    this.props.setError(name, message);
                    // Track event
                    componentInstance.props.trackEvent("Client error", "Scans_getChecks_3");
                    componentInstance.props.resetLoading();
                });
        } catch (error) {
            // Set error
            var name = "Server error";
            var message = "Something went wrong. Please try again later";
            this.props.setError(name, message);
            // Track event
            componentInstance.props.trackEvent("Client error", "Scans_getChecks_4");
            componentInstance.props.resetLoading();
        }
        return false;
    }



    render() {
        return (
            <div className="section">
                <div className="textbox text-title text-bold">
                    Your scans
                </div>

                {this.state.data.length >= 1 &&
                <div className="scanlist-container">

                    {this.state.data.map((scan, index) =>
                    <Link key={index} to={"/account/scan/" + scan.file_name} className="scanlist__scan">
                        <div className="scanlist__scan-wrapper">
                            <div className="scanlist__scan__date">
                                {formatDatetime(scan.timestamp)}
                            </div>
                            {/* <div className="scanlist__scan__mole">
                                Location
                            </div> */}
                            <div className={"scanlist__scan__result" + (scan.risk_level === 1 || scan.risk_level === 2 ? " scanlist__scan__result--risk" : "")}>
                                {scan.risk_level === 2 ? "High risk": ""}
                                {scan.risk_level === 1 ? "Medium risk": ""}
                                {scan.risk_level === 0 ? "Low risk": ""}
                            </div>
                            {/* <div className="scanlist__scan__comment">
                                Follow-up
                            </div> */}
                        </div>
                    </Link>
                    )}

                </div>
                }

                {this.state.data.length <= 0 &&
                <div className="textbox">
                    You don't have any scans yet. You can check your moles <Link className="text-link-inline" to="/check">here</Link>.
                </div>
                }

            </div>
        )
    }
}
