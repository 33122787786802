import React, { Component } from 'react';
import { ReactComponent as IconCheck } from '../../media/icons/check.svg';
import { ReactComponent as IconCross } from '../../media/icons/cross.svg';

export default class Instructions extends Component {


    componentDidMount() {
        // Track pageview
        this.props.pageview();
    }

    
    render() {
        return (
            <div className="content-container">
                <div className="content-wrapper">
                    <div className="section section-instructions">

                        <div className="check__instruction">
                            <div className="check__instruction__title textbox text-bold">
                                The mole is in the center of the image
                            </div>
                            <div className="check__instruction__image">
                                <div className="check__instruction__image__img check__instruction__image__img--negative-center"></div>
                                <IconCross className="check__instruction__image__icon check__instruction__image__icon--no" />
                            </div>
                            <div className="check__instruction__image">
                                <div className="check__instruction__image__img check__instruction__image__img--positive"></div>
                                <IconCheck className="check__instruction__image__icon check__instruction__image__icon--yes" />
                            </div>
                        </div>

                        <div className="check__instruction">
                            <div className="check__instruction__title textbox text-bold">
                                The mole is neither too big nor too small - about 15cm (6in) away from the camera
                            </div>
                            <div className="check__instruction__image">
                                <div className="check__instruction__image__img check__instruction__image__img--negative-size"></div>
                                <IconCross className="check__instruction__image__icon check__instruction__image__icon--no" />
                            </div>
                            <div className="check__instruction__image">
                                <div className="check__instruction__image__img check__instruction__image__img--positive"></div>
                                <IconCheck className="check__instruction__image__icon check__instruction__image__icon--yes" />
                            </div>
                        </div>

                        <div className="check__instruction">
                            <div className="check__instruction__title textbox text-bold">
                                The mole is sufficiently visible and light conditions are good
                            </div>
                            <div className="check__instruction__image">
                                <div className="check__instruction__image__img check__instruction__image__img--negative-light"></div>
                                <IconCross className="check__instruction__image__icon check__instruction__image__icon--no" />
                            </div>
                            <div className="check__instruction__image">
                                <div className="check__instruction__image__img check__instruction__image__img--positive"></div>
                                <IconCheck className="check__instruction__image__icon check__instruction__image__icon--yes" />
                            </div>
                        </div>

                        <div className="check__instruction">
                            <div className="check__instruction__title textbox text-bold">
                                Only the mole and your skin are visible - no clothing, tattoos or other patterns
                            </div>
                            <div className="check__instruction__image">
                                <div className="check__instruction__image__img check__instruction__image__img--negative-visible"></div>
                                <IconCross className="check__instruction__image__icon check__instruction__image__icon--no" />
                            </div>
                            <div className="check__instruction__image">
                                <div className="check__instruction__image__img check__instruction__image__img--positive"></div>
                                <IconCheck className="check__instruction__image__icon check__instruction__image__icon--yes" />
                            </div>
                        </div>

                        <div className="check__instruction">
                            <div className="check__instruction__title textbox text-bold">
                                A plain background is used
                            </div>
                            <div className="check__instruction__image">
                                <div className="check__instruction__image__img check__instruction__image__img--negative-background"></div>
                                <IconCross className="check__instruction__image__icon check__instruction__image__icon--no" />
                            </div>
                            <div className="check__instruction__image">
                                <div className="check__instruction__image__img check__instruction__image__img--positive"></div>
                                <IconCheck className="check__instruction__image__icon check__instruction__image__icon--yes" />
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        )
    }
}
